import { useContext, useMemo } from 'react'
import { ConfigContext } from '../store/ConfigContext'
import {
  ConfigurationDefinitionsKeys,
  ContentStackTypes
} from '../store/Constants'

export const useHpPlusClient = () => {
  const { pageDataCache, printer, localization } = useContext(ConfigContext)

  const hpPlusCountries = useMemo(() => {
    return JSON.parse(
      (
        pageDataCache?.get(
          ContentStackTypes.configuration_definitions.content_type_uid
        ) || new Map()
      )?.definitions?.find(
        (definition) =>
          definition.configuration_key ===
          ConfigurationDefinitionsKeys.hpPlusCountries
      )?.configuration_value || '[]'
    )
  }, [pageDataCache])

  return {
    isHpPlus: printer?.programs?.includes('hp+'),
    isHpPlusCountry: (hpPlusCountries || [])
      .map((country) => country.toLowerCase())
      .includes(localization.country.toLowerCase())
  }
}
